import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link, Tabs } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/tabs/code",
  "title": "Tabs - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Tabs>
  <Tabs.TabList style={{ marginBottom: 'var(--spacing-m)' }}>
    <Tabs.Tab>Daycare</Tabs.Tab>
    <Tabs.Tab>Pre-school</Tabs.Tab>
    <Tabs.Tab>Basic education</Tabs.Tab>
    <Tabs.Tab>Upper secondary</Tabs.Tab>
    <Tabs.Tab>University</Tabs.Tab>
  </Tabs.TabList>
  <Tabs.TabPanel>
    Daytime care for people who cannot be fully independent, such as children or elderly people.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    A pre-school is an educational establishment offering early childhood education to children before they begin
    compulsory education at primary school.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    The objective of basic education in Finland is to support pupils&#39; growth towards humanity and ethically
    responsible membership of society.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    Upper secondary school studies last three to four years, preparing the students for the matriculation examination.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    A high-level educational institution in which students study for degrees and academic research is done.
  </Tabs.TabPanel>
</Tabs>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "small",
      "style": {
        "position": "relative"
      }
    }}>{`Small`}<a parentName="h4" {...{
        "href": "#small",
        "aria-label": "small permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Tabs small>
  <Tabs.TabList style={{ marginBottom: 'var(--spacing-m)' }}>
    <Tabs.Tab>Daycare</Tabs.Tab>
    <Tabs.Tab>Pre-school</Tabs.Tab>
    <Tabs.Tab>Basic education</Tabs.Tab>
    <Tabs.Tab>Upper secondary</Tabs.Tab>
    <Tabs.Tab>University</Tabs.Tab>
  </Tabs.TabList>
  <Tabs.TabPanel>
    Daytime care for people who cannot be fully independent, such as children or elderly people.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    A pre-school is an educational establishment offering early childhood education to children before they begin
    compulsory education at primary school.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    The objective of basic education in Finland is to support pupils&#39; growth towards humanity and ethically
    responsible membership of society.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    Upper secondary school studies last three to four years, preparing the students for the matriculation examination.
  </Tabs.TabPanel>
  <Tabs.TabPanel>
    A high-level educational institution in which students study for degrees and academic research is done.
  </Tabs.TabPanel>
</Tabs>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "overflow",
      "style": {
        "position": "relative"
      }
    }}>{`Overflow`}<a parentName="h4" {...{
        "href": "#overflow",
        "aria-label": "overflow permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ maxWidth: '400px' }}>
  <Tabs>
    <Tabs.TabList style={{ marginBottom: 'var(--spacing-m)' }}>
      <Tabs.Tab>Daycare</Tabs.Tab>
      <Tabs.Tab>Pre-school</Tabs.Tab>
      <Tabs.Tab>Basic education</Tabs.Tab>
      <Tabs.Tab>Upper secondary</Tabs.Tab>
      <Tabs.Tab>University</Tabs.Tab>
    </Tabs.TabList>
    <Tabs.TabPanel>
      Daytime care for people who cannot be fully independent, such as children or elderly people.
    </Tabs.TabPanel>
    <Tabs.TabPanel>
      A pre-school is an educational establishment offering early childhood education to children before they begin
      compulsory education at primary school.
    </Tabs.TabPanel>
    <Tabs.TabPanel>
      The objective of basic education in Finland is to support pupils&#39; growth towards humanity and ethically
      responsible membership of society.
    </Tabs.TabPanel>
    <Tabs.TabPanel>
      Upper secondary school studies last three to four years, preparing the students for the matriculation
      examination.
    </Tabs.TabPanel>
    <Tabs.TabPanel>
      A high-level educational institution in which students study for degrees and academic research is done.
    </Tabs.TabPanel>
  </Tabs>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-tabs--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/stepper" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-tabs--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the small tabs variant is used.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Tabs properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      